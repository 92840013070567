import { Injectable } from '@angular/core';
import {Subject} from "rxjs/Subject";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Rutas} from "../../../../../utiles/rutas";

@Injectable({
  providedIn: 'root'
})
export class CrudproyectosService {

  private proyectosSource = new Subject<any>();
  public proyectos$ = this.proyectosSource.asObservable();


  constructor(private http: HttpClient) { }


  delete(id){
    return this.http.delete(Rutas + 'delete/proyecto?token='+ localStorage.getItem('token')+'&id='+id);
  }


  setProyecto (nombrePro, descripcionPro){

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const proyecto = {
      nombre: nombrePro,
      descripcion: descripcionPro
    };
    const json = { proyecto };
    const data = JSON.stringify(json);
    const params = 'token=' + localStorage.getItem('token') + '&json=' + data;
    console.log(params);
    this.http.post(Rutas + 'add/proyecto', params, { headers: headers }).subscribe( res =>{
      console.log(res);
    });
  }

  setTarea (id, nombreT, descripcionT){
    console.log(id);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const tarea = {
      nombre: nombreT,
      descripcion: descripcionT
    };
    const json = { tarea };
    const data = JSON.stringify(json);
    const params = '&token=' + localStorage.getItem('token') + '&json=' + data;
    console.log(params);
    this.http.post(Rutas + 'add/tarea?id='+id, params, { headers: headers }).subscribe( res =>{
      console.log(res);
    });
  }

  setItem (id, nombreT, descripcionT){
    // console.log(id);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const item = {
      nombre: nombreT,
      descripcion: descripcionT
    };
    const json = { item };
    const data = JSON.stringify(json);
    const params = '&token=' + localStorage.getItem('token') + '&json=' + data;
    // console.log(params);
    this.http.post(Rutas + 'add/item?id='+id, params, { headers: headers }).subscribe( res =>{
      console.log(res);
    });
  }

  getProyectos(){
    this.http.get(Rutas + 'get/proyectos?token=' + localStorage.getItem('token')).subscribe(data => {
      // console.log("crudproyectos.components -> data: ", data);
      this.proyectosSource.next(data);
    }, error => {
      console.log(error);
        }
    );}



}


