import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subject} from 'rxjs/Subject';
import {Rutas} from '../../../../../utiles/rutas';

@Injectable()
export class EmpleadosService {
    public usersSource = new Subject<any>();
    public users$ = this.usersSource.asObservable();

    public fichadosSource = new Subject<any>();
    public fichados$ = this.fichadosSource.asObservable();

    public empresasSource = new Subject<any>();
    public empresas$ = this.empresasSource.asObservable();

    private configSource = new Subject<any>();
    public config$ = this.configSource.asObservable();

    private fechasSource = new Subject<any>();
    public fechas$ = this.fechasSource.asObservable();

    public fichajesSource = new Subject<any>();
    public fichajes$ = this.fichajesSource.asObservable();

    private anosSource = new Subject<any>();
    public anos$ = this.anosSource.asObservable();

    private mesesSource = new Subject<any>();
    public meses$ = this.mesesSource.asObservable();

    private diasSource = new Subject<any>();
    public dias$ = this.diasSource.asObservable();

    private entrefechasSource = new Subject<any>();
    public entrefechas$ = this.entrefechasSource.asObservable();

    constructor(private http: HttpClient) {
    }

    getEmpleados(role) {

        this.http.get(Rutas + 'get/users?token=' + localStorage.getItem('token') + '&role=' + role).subscribe(res => {
            // console.log(res);
            if (res['code'] === 200) {
                this.usersSource.next(res['id']);
            }
        }, error => {
            // console.log(error);
            return error;
        });
    }

    getUsers(tipo: number, alerta: number, fecha: string) {
        // tslint:disable-next-line:max-line-length
        this.http.get(Rutas + 'fichar/users?token=' + localStorage.getItem('token') + '&tipo=' + tipo + '&alerta=' + alerta + '&fecha=' + fecha).subscribe(res => {
            // console.log(res);
            if (res['code'] === 200) {
                this.fichadosSource.next(res['id']);
                localStorage.setItem('empleados', JSON.stringify(res['id']));

            }
        }, error => {
            // console.log(error);
            return error;
        });
    }

    getUsersSeguimiento(tipo: number, empresa: number, departamento: number, fecha: string) {
        // tslint:disable-next-line:max-line-length
        this.http.get(`${Rutas}fichar/seguimiento/users?token=${localStorage.getItem('token')}&tipo=${tipo}&empresa=${empresa}&departamento=${departamento}&fecha=${fecha}`).subscribe(res => {
            // console.log(res);
            if (res['code'] === 200) {
                this.fichadosSource.next(res['id']);
            }
        }, error => {
            // console.log(error);
        });
    }

    getFechas(empresa: number, departamento: number) {
        // tslint:disable-next-line:max-line-length
        this.http.get(`${Rutas}fichar/fechas?token=${localStorage.getItem('token')}&empresa=${empresa}&departamento=${departamento}`).subscribe(res => {
            // console.log(res);
            if (res['code'] === 200) {
                this.fechasSource.next(res['id']);
            }
        }, error => {
            console.log(error);
        });
    }

    getConfig() {
        this.http.get(Rutas + 'fichar/get/config?token=' + localStorage.getItem('token')).subscribe(res => {
            // console.log(res);
            if (res['code'] === 200) {
                this.configSource.next(res['id']);
            }
        }, error => {
            console.log(error);
        });
    }

    getFichajesEmpleado(id: number) {
        this.http.get(Rutas + 'fichajes/user?id=' + id + '&token=' + localStorage.getItem('token')).subscribe(res => {
            // console.log(res);
            if (res['code'] === 200) {
                this.fichajesSource.next(res['id']);
            }
        }, error => {
            console.log(error);
        });
    }

    getAnosEmpleado(id: number) {
        this.http.get(Rutas + 'fichar/ano/fechas?id=' + id + '&token=' + localStorage.getItem('token')).subscribe(res => {
            // console.log(res);
            if (res['code'] === 200) {
                this.anosSource.next(res['id']);
            }
        }, error => {
            console.log(error);
        });
    }

    getMesesEmpleado(id: number, ano: number) {
        this.http.get(`${Rutas}fichar/meses/fechas?id=${id}&ano=${ano}&token=${localStorage.getItem('token')}`).subscribe(res => {
            // console.log(res);
            if (res['code'] === 200) {
                this.mesesSource.next(res['id']);
            }
        }, error => {
            console.log(error);
        });
    }

    getDiasEmpleado(id: number, ano: number, mes: number) {
        // tslint:disable-next-line:max-line-length
        this.http.get(`${Rutas}fichar/dias/fechas?id=${id}&ano=${ano}&mes=${mes}&token=${localStorage.getItem('token')}`).subscribe(res => {
            // console.log(res);
            if (res['code'] === 200) {
                this.diasSource.next(res['id']);
            }
        }, error => {
            console.log(error);
        });
    }

    getFichajesEmpleadoFecha(id: number, tipo: string, ano?: number, mes?: number, dia?: number) {
        // tslint:disable-next-line:max-line-length
        this.http.get(`${Rutas}fichajes/empleado/fecha?id=${id}&ano=${ano}&tipo=${tipo}&mes=${mes}&dia=${dia}&token=${localStorage.getItem('token')}`).subscribe(res => {
            // console.log(res);
            if (res['code'] === 200) {
                this.fichajesSource.next(res['id']);
            }
        }, error => {
            console.log(error);
        });
    }

    getFichajesEntreFechas(id: number, inicio: Date, fin: Date) {
        // tslint:disable-next-line:max-line-length
        this.http.get(`${Rutas}fichajes/empleado/entre/fechas?id=${id}&inicio=${inicio}&fin=${fin}&token=${localStorage.getItem('token')}`).subscribe(res => {
            // console.log(res);
            if (res['code'] === 200) {
                this.fichajesSource.next(res['id']);
            }
        }, error => {
            console.log(error);
        });
    }

    getFechasEntreFechas(id: number, inicio: Date, fin: Date) {
        // tslint:disable-next-line:max-line-length
        this.http.get(`${Rutas}fechas/entre/fechas?id=${id}&inicio=${inicio}&fin=${fin}&token=${localStorage.getItem('token')}`).subscribe(res => {
            // console.log(res);
            if (res['code'] === 200) {
                this.entrefechasSource.next(res['id']);
            }
        }, error => {
            console.log(error);
        });
    }

    getEmpresas() {
        // tslint:disable-next-line:max-line-length
        this.http.get(Rutas + 'get/empresas?token=' + localStorage.getItem('token')).subscribe(res => {
            // console.log(res);
            if (res['code'] === 200) {
                this.empresasSource.next(res['id']);
            }
        }, error => {
            console.log(error);
        });
    }

    forzarFichaje(user: any) {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const json = {user};
        const data = JSON.stringify(json);
        const params = 'json=' + data + '&token=' + localStorage.getItem('token');

        this.http.post(Rutas + 'forzar/fichaje', params, {headers: headers}).subscribe(res => {
            console.log(res);
            if (res['code'] === 200) {
                this.entrefechasSource.next(res['id']);
            }
        }, error => {
            console.log(error);
        });
    }

    removeEmpleado(idEmpleado: any) {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const params = 'json=' + idEmpleado + '&token=' + localStorage.getItem('token');
        return this.http.post(Rutas + 'remove/empleado', params, {headers: headers});
    }

    updateFichaje(fichajeUpdateData: any) {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const json = {fichajeUpdateData};
        const data = JSON.stringify(json);
        const params = 'json=' + data + '&token=' + localStorage.getItem('token');

        console.log(Rutas + 'update/fichaje', params, {headers: headers});
        return this.http.post(Rutas + 'update/fichaje', params, {headers: headers});
    }

    removeFichaje(ids_entrada_salida: any) {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const json = {ids_entrada_salida};
        const data = JSON.stringify(json);
        const params = 'json=' + data + '&token=' + localStorage.getItem('token');

        return this.http.post(Rutas + 'remove/fichaje', params, {headers: headers});
    }

    addFichaje(fichajeAdd: any) {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const json = {fichajeAdd};
        const data = JSON.stringify(json);
        const params = 'json=' + data + '&token=' + localStorage.getItem('token');

        return this.http.post(Rutas + 'add/fichaje', params, {headers: headers});
    }
}
