import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../pages/services/login.service';
import { RouteInfo } from '../../../modelos/sidebar.modelo';
import { ROUTES } from '../../../utiles/rutas';

// declare const $: any;


@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
    public links: RouteInfo[];

    constructor(private router: Router, private _login: LoginService, private renderer: Renderer2) {
        this.links = ROUTES;
    }

    ngOnInit() {

    }

    ngOnDestroy() {

    }

    desplegarsubmenu(i) {
        const elemento = document.getElementById('desplegable' + i);
        const submenu = document.getElementById('listadesplegada' + i);
        elemento.classList.toggle('show');
        submenu.classList.toggle('show');

        // if (elemento.classList.contains('show')) {
        //     this.renderer.removeClass(elemento, 'show');
        //     this.renderer.removeClass(submenu, 'show');
        // } else {
        //     this.renderer.addClass(elemento, 'show');
        //     this.renderer.addClass(submenu, 'show');
        // }
        this.isDropdownOpen(elemento, submenu);
    }

    isDropdownOpen(elemento, submenu) {
        // funcion para cualquier clic en el documento
        const rend = this.renderer;
        document.addEventListener('click', function (e) {
            // obtiendo informacion del DOM para
            const clic = e.target;
            // tslint:disable-next-line:max-line-length
            if (elemento.classList.contains('show') && clic !== elemento && clic !== elemento.firstChild && clic !== elemento.firstChild.firstChild && clic !== elemento.firstChild.lastChild) {
                rend.removeClass(elemento, 'show');
                rend.removeClass(submenu, 'show');
            }
        }, false);
    }

}
