import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { LoginService } from '../pages/services/login.service';
import { Rutas } from '../../utiles/rutas';



@Injectable()
export class UploadService {
  private url: string;
  private action: string;

  constructor(private _http: HttpClient, private _login: LoginService) {
    this.url = Rutas + 'upload/img';
  }

  makeFileRequest(id, files: Array<File>, name: string, action: string) {
    const url = this.url;
    const token = this._login.getToken();
    const tipo = action;
    return new Promise(function (resolve, reject) {
      const formData: any = new FormData();
      const xhr = new XMLHttpRequest();
      for (let i = 0; i < files.length; i++) {
        formData.append(name, files[i], files[i].name);
      }
      formData.append('token', token);
      formData.append('id', id);
      formData.append('tipo', tipo);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      };
      xhr.open('POST', url, true);
      xhr.send(formData);
    });
  }


}
