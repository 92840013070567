import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Proyecto} from "../../../../modelos/proyectos.modelo";
import {Subscription} from "rxjs";
import {CrudproyectosService} from "./services/crudproyectos.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormControl, FormGroup} from "@angular/forms";
import {User} from "../../../../modelos/user.modelo";
import {EmpleadosService} from "../empleados/services/empleados.service";
import * as XLSX from 'xlsx';
import {DatePipe} from '@angular/common';
import swal from "sweetalert";

@Component({
    selector: 'app-proyectos',
    templateUrl: './proyectos.component.html',
    styleUrls: ['./proyectos.component.css']
})
export class ProyectosComponent implements OnInit, OnDestroy {
    @ViewChild('TABLE') TABLE: ElementRef;
    disable: boolean = true;
    users: Array<User> = [];
    proyectos: Array<Proyecto> = [];
    proyectoSubscription = new Subscription();
    modalForm: FormGroup;
    paginas: Array<any>;

    constructor(private _crud: CrudproyectosService,
                private modalService: NgbModal,
                private _empleados: EmpleadosService,
                private datePipe: DatePipe) {
        this._crud.getProyectos();
    }

    ngOnInit() {
        this.modalForm = new FormGroup({
                proyectoName: new FormControl(),
                proyectoDescripcion: new FormControl(),
                tareaName: new FormControl(),
                tareaDescripcion: new FormControl(),
                itemName: new FormControl(),
                itemDescripcion: new FormControl()
            }
        );


        this.proyectoSubscription = this._crud.proyectos$.subscribe(res => {
            if (res) {
                this.proyectos = res;
                console.log("proyectos.components -> this.proyectos: ", this.proyectos);
            }
        });
    }
    ngOnDestroy(): void {
        this.proyectoSubscription.unsubscribe();
    }

    open(content) {
        this.modalService.open(content, {
            centered: true
        });
    }

    addProyecto() {
        this.modalService.dismissAll();
        const form = this.modalForm.getRawValue();
        const nombre = form.proyectoName;
        const descripcion = form.proyectoDescripcion;
        this._crud.setProyecto(nombre, descripcion);
        this._crud.getProyectos();
    }

    addtarea(id) {
        console.log(id);
        this.modalService.dismissAll();
        const form = this.modalForm.getRawValue();
        const nombre = form.tareaName;
        const descripcion = form.tareaDescripcion;
        this.modalForm.reset()
        this._crud.setTarea(id, nombre, descripcion);
        this._crud.getProyectos();
    }

    addItem(id) {
        // console.log(id);
        this.modalService.dismissAll();
        const form = this.modalForm.getRawValue();
        const nombre = form.itemName;
        const descripcion = form.itemDescripcion;
        this.modalForm.reset()
        this._crud.setItem(id, nombre, descripcion);
        this._crud.getProyectos();
    }

    pipeNombres(id) {
        const empleados = JSON.parse(localStorage.getItem('empleados'));
        // console.log(empleados);
        var arrayNombres = empleados.filter(function (el) {
            return el.id_user == id;
        });
        // console.log(arrayNombres);
        return `${arrayNombres[0].nombre} ${arrayNombres[0].apellidos}`;
    }

    calculaHoras(inicio, fin) {

        if (fin != null) {
            const tempI = inicio.slice(0, 19);
            const tempF = fin.slice(0, 19);
            const datetimepartsInicio = tempI.split(/[- T :  ]/);
            const datetimepartsFin = tempF.split(/[- T : ]/);
            datetimepartsInicio[1];
            datetimepartsFin[1];

            const dateIn = new Date(datetimepartsInicio[0],
                datetimepartsInicio[1],
                datetimepartsInicio[2],
                datetimepartsInicio[3],
                datetimepartsInicio[4],
                datetimepartsInicio[5]);

            const dateFin = new Date(datetimepartsInicio[0],
                datetimepartsFin[1],
                datetimepartsFin[2],
                datetimepartsFin[3],
                datetimepartsFin[4],
                datetimepartsFin[5]);

            const dif = dateFin.getTime() - dateIn.getTime();
            return dif;
        }
        return 0;
    }

    ExportTOExcel(type: number, tareaID?: number, proyectoID?: number) {
        if (type == 0) {
            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataToJson("all" , proyectoID, tareaID));
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, 'Exportacion.xlsx');
        }

        if (type == 1) {
            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataToJson("p" , proyectoID, tareaID));
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, this.proyectos[proyectoID].nombre);
            XLSX.writeFile(wb, this.proyectos[proyectoID].nombre + '.xlsx');
        }

        if (type == 2) {
            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataToJson("t" , proyectoID, tareaID));
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, this.proyectos[proyectoID].tareas[tareaID].nombre);
            XLSX.writeFile(wb, this.proyectos[proyectoID].tareas[tareaID].nombre + '.xlsx');
        }

        if (type == 3) {
            const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, 'Item.xlsx');
        }
    }

    delete(id, nombre){

        // @ts-ignore
        swal({
            title: 'BORRAR',
            text: '¿Seguro que quiere borrar ' + nombre + '?',
            icon: 'error',
            buttons: true,
            dangerMode: true
        }).then((willDelete) => {
            if(willDelete){
                this._crud.delete(id).subscribe( res =>{
                    if( res['code'] == 200){
                        this._crud.getProyectos();
                    }
                });


            }

        });


    }


    dataToJson(type: string, proyecto: number, tarea: number) {

        let eachProduct = [];

        if (type === "all") {
            for (let i = 0; i < this.proyectos.length - 1; i++) {
                for (let j = 0; j < this.proyectos[i].tareas.length - 1; j++) {
                    for (let k = 0; k < this.proyectos[i].tareas[j].items.length - 1; k++) {
                        for (let l = 0; l < this.proyectos[i].tareas[j].items[k].horas_fk.length - 1; l++) {
                            const item = this.proyectos[i].tareas[j].items[k].nombre;
                            const proyecto = this.proyectos[i].nombre;
                            const tarea = this.proyectos[i].tareas[j].nombre;
                            const horas_FK_ID = this.proyectos[i].tareas[j].items[k].horas_fk[l].user_id;
                            const horas_FK_Usurio = this.pipeNombres(this.proyectos[i].tareas[j].items[k].horas_fk[l].user_id);
                            const horas_FK_Comentario = this.proyectos[i].tareas[j].items[k].horas_fk[l].comentario;
                            const horas_FK_Inicio = this.proyectos[i].tareas[j].items[k].horas_fk[l].inicio;
                            const horas_FK_Fin = this.proyectos[i].tareas[j].items[k].horas_fk[l].fin;

                            // Horas totales
                            const dif = this.calculaHoras(horas_FK_Inicio, horas_FK_Fin);
                            const minutos = ((dif / (1000 * 60)) % 60);
                            const horas = ((dif / (1000 * 60 * 60)) % 24);
                            const horas_FK_Total = `${Math.floor(horas)} h ${Math.floor(minutos)}`;

                            eachProduct.push({
                                "Proyecto": proyecto,
                                "Tarea": tarea,
                                "Item": item,
                                "ID Usuario": horas_FK_ID,
                                "Usuario": horas_FK_Usurio.toString(),
                                "Comentario": horas_FK_Comentario,
                                "Inicio": this.datePipe.transform(horas_FK_Inicio, 'yyyy-MM-dd HH:MM'),
                                "Fin": this.datePipe.transform(horas_FK_Fin, 'yyyy-MM-dd HH:MM'),
                                "Total": horas_FK_Total
                            })
                        }
                    }
                }
            }
        }
        if (type === "p") {
            for (let j = 0; j < this.proyectos[proyecto].tareas.length; j++) {
                for (let k = 0; k < this.proyectos[proyecto].tareas[j].items.length; k++) {
                    for (let l = 0; l < this.proyectos[proyecto].tareas[j].items[k].horas_fk.length; l++) {
                        const proyectoName = this.proyectos[proyecto].nombre;
                        const tarea = this.proyectos[proyecto].tareas[j].nombre;
                        const item = this.proyectos[proyecto].tareas[j].items[k].nombre;
                        const horas_FK_ID = this.proyectos[proyecto].tareas[j].items[k].horas_fk[l].user_id;
                        const horas_FK_Usurio = this.pipeNombres(this.proyectos[proyecto].tareas[j].items[k].horas_fk[l].user_id);
                        const horas_FK_Comentario = this.proyectos[proyecto].tareas[j].items[k].horas_fk[l].comentario;
                        const horas_FK_Inicio = this.proyectos[proyecto].tareas[j].items[k].horas_fk[l].inicio;
                        const horas_FK_Fin = this.proyectos[proyecto].tareas[j].items[k].horas_fk[l].fin;

                        // Horas totales
                        const dif = this.calculaHoras(horas_FK_Inicio, horas_FK_Fin);
                        const minutos = ((dif / (1000 * 60)) % 60);
                        const horas = ((dif / (1000 * 60 * 60)) % 24);
                        const horas_FK_Total = `${Math.floor(horas)} h ${Math.floor(minutos)}`;

                        eachProduct.push({
                            "Proyecto": proyectoName,
                            "Tarea": tarea,
                            "Item": item,
                            "ID Usuario": horas_FK_ID,
                            "Usuario": horas_FK_Usurio.toString(),
                            "Comentario": horas_FK_Comentario,
                            "Inicio": this.datePipe.transform(horas_FK_Inicio, 'yyyy-MM-dd HH:MM'),
                            "Fin": this.datePipe.transform(horas_FK_Fin, 'yyyy-MM-dd HH:MM'),
                            "Total": horas_FK_Total
                        })
                    }
                }
            }
        }
        if (type === "t") {
                for (let k = 0; k < this.proyectos[proyecto].tareas[tarea].items.length; k++) {
                    for (let l = 0; l < this.proyectos[proyecto].tareas[tarea].items[k].horas_fk.length; l++) {
                        const proyectoName = this.proyectos[proyecto].nombre;
                        const tareaName = this.proyectos[proyecto].tareas[tarea].nombre;
                        const item = this.proyectos[proyecto].tareas[tarea].items[k].nombre;
                        const horas_FK_ID = this.proyectos[proyecto].tareas[tarea].items[k].horas_fk[l].user_id;
                        const horas_FK_Usurio = this.pipeNombres(this.proyectos[proyecto].tareas[tarea].items[k].horas_fk[l].user_id);
                        const horas_FK_Comentario = this.proyectos[proyecto].tareas[tarea].items[k].horas_fk[l].comentario;
                        const horas_FK_Inicio = this.proyectos[proyecto].tareas[tarea].items[k].horas_fk[l].inicio;
                        const horas_FK_Fin = this.proyectos[proyecto].tareas[tarea].items[k].horas_fk[l].fin;

                        // Horas totales
                        const dif = this.calculaHoras(horas_FK_Inicio, horas_FK_Fin);
                        const minutos = ((dif / (1000 * 60)) % 60);
                        const horas = ((dif / (1000 * 60 * 60)) % 24);
                        const horas_FK_Total = `${Math.floor(horas)} h ${Math.floor(minutos)}`;

                        eachProduct.push({
                            "Proyecto": proyectoName,
                            "Tarea": tareaName,
                            "Item": item,
                            "ID Usuario": horas_FK_ID,
                            "Usuario": horas_FK_Usurio.toString(),
                            "Comentario": horas_FK_Comentario,
                            "Inicio": this.datePipe.transform(horas_FK_Inicio, 'yyyy-MM-dd HH:MM'),
                            "Fin": this.datePipe.transform(horas_FK_Fin, 'yyyy-MM-dd HH:MM'),
                            "Total": horas_FK_Total
                        })
                    }
                }
        }

        return eachProduct;
    }
}
