<div>
    <h3 class="ml-5">Gestión de proyectos</h3>
</div>
<!-- Boton añadir proyecto-->
<div class="row">
    <div class="col-sm-12  mt-1">
        <button class=" ml-5 btn btn-link btn-md center-block" (click)="open(proyectoADD)">
            <i class="fas fa-plus-square"></i> Añadir proyecto
        </button>

        <button class=" ml-5 btn btn-link btn-md center-block" (click)="ExportTOExcel(0)">
            <i class="fas fa-plus-square"></i> Exportar todo
        </button>
    </div>
</div>

<!-- Acordeon proyectos -->
<div class="ml-5 mr-5 mt-1" *ngIf=" proyectos.length != 0">
    <ngb-accordion #a="ngbAccordion" activeIds="proyecto-1">
        <ngb-panel *ngFor="let proyecto of proyectos; index as p">
            <ng-template ngbPanelHeader>
                <div class="d-flex align-items-center justify-content-between ">
                    <h5 class="m-0">{{ proyecto.nombre}}</h5>
                    <!-- Div de botones en Titulo -->
                    <div>
                        <button class=" no-border bg-transparent "><i class="far fa-edit"></i></button>
                        <button class=" no-border bg-transparent " (click)="delete(proyecto.id, proyecto.nombre)"><i class="far fa-trash-alt"></i></button>
                        <button class=" no-border bg-transparent ">
                            <i class="fas fa-file-export" (click)="ExportTOExcel(1, 0, p)"> </i>
                        </button>
                        <button ngbPanelToggle class=" no-border bg-transparent"><i class="fas fa-grip-lines"></i>
                        </button>
                    </div>
                </div>

                <!-- Contenido de acordeon proyectos -->
            </ng-template>
            <ng-template ngbPanelContent>
                <label>{{ proyecto.descripcion}}</label>
                <!-- Boton añadir tarea-->
                <div class="row">
                    <div class="col-sm-12  mt-1">
                        <button class="  btn btn-link btn-md center-block" (click)="open(tareaADD)">
                            <i class="fas fa-plus-square"></i> Añadir tarea
                        </button>
                    </div>
                </div>

                <!-- Acorderon tareas hijo de acordeon proyectos -->
                <ngb-accordion #a="ngbAccordion">
                    <ngb-panel *ngFor="let tarea of proyecto.tareas; index as t">
                        <ng-template ngbPanelHeader>
                            <div class="d-flex align-items-center justify-content-between">
                                <h5 class="ml-2 ">{{ tarea.nombre}}</h5>
                                <!-- Div de botones en Titulo de tareas -->
                                <div>
                                    <button class=" no-border bg-transparent ">
                                        <i class="far fa-edit"></i>
                                    </button>
                                    <button class=" no-border bg-transparent ">
                                        <i class="far fa-trash-alt"></i>
                                    </button>
                                    <button class=" no-border bg-transparent ">
                                        <i class="fas fa-file-export" (click)="ExportTOExcel(2, t, p)"> </i>
                                    </button>
                                    <button ngbPanelToggle class class="no-border bg-transparent">
                                        <i class="fas fa-grip-lines "></i>
                                    </button>
                                </div>
                            </div>
                        </ng-template>
                        <!-- Contenido acordeon Tareas -->
                        <ng-template ngbPanelContent>
                            <label>{{ tarea.descripcion}}</label>
                            <!-- Boton añadir item -->
                            <div class="row">
                                <div class="col-sm-12  mt-1">
                                    <button class="  btn btn-link btn-md center-block" (click)="open(itemADD)"><i
                                            class="fas fa-plus-square"></i> Añadir item
                                    </button>
                                </div>
                            </div>

                            <ngb-accordion #a="ngbAccordion">
                                <ngb-panel *ngFor="let item of tarea.items">
                                    <ng-template ngbPanelHeader>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <h5 class="m-0 ">{{ item.nombre}}</h5>
                                            <!-- Div de botones en Titulo de items -->
                                            <div>
                                                <button class=" no-border bg-transparent "><i class="far fa-edit"></i>
                                                </button>
                                                <button class=" no-border bg-transparent "><i
                                                        class="far fa-trash-alt"></i></button>
                                                <button class=" no-border bg-transparent " (click)="ExportTOExcel(3, t, p)"><i
                                                        class="fas fa-file-export"></i></button>
                                                <button ngbPanelToggle class class="no-border  bg-transparent"><i
                                                        class="fas fa-grip-lines "></i></button>
                                            </div>
                                        </div>
                                        <!-- Contenido de items -->
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <label>{{ item.descripcion}}</label>

                                        <!-- Listado de horas de items -->

                                        <div class="card mb-3" *ngIf="disable">
                                            <div class="card-body">
                                                <div class="table-responsive">
                                                    <table class="table table-striped" id="dataTable" width="100%"
                                                           cellspacing="0" data-page-length="10"
                                                           data-paging="true" #TABLE>
                                                        <!--            <table class="table table-striped table-dark" id="dataTable" width="100%" cellspacing="0" data-page-length="10"  data-paging="true">-->
                                                        <thead class="thead-oscuro">
                                                        <tr>
                                                            <th colspan="6" hidden>{{proyecto.nombre}}</th>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="6" hidden>{{item.nombre}}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>NOMBRE</th>
                                                            <th>COMENTARIOS</th>
                                                            <th>INICIO</th>
                                                            <th>FIN</th>
                                                            <th>TOTAL</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr *ngFor="let hora of item.horas_fk; let i = index">
                                                            <td>{{hora.user_id }}</td>
                                                            <td>{{(pipeNombres(hora.user_id))}}</td>
                                                            <td>{{hora.comentario | titlecase}}</td>
                                                            <td>{{hora.inicio | date: ' dd/MM/yyyy HH:MM'}} </td>
                                                            <td>{{hora.fin | date: ' dd/MM/yyyy HH:MM'}} </td>
                                                            <td>{{ (calculaHoras(hora.inicio, hora.fin)) | horasCalculo }}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>

                            <!-- Modal add item -->
                            <ng-template #itemADD let-modal>
                                <div class="modal-header">
                                    <h4 class="modal-title " id="modal-basic-title-3">AÑADIR ITEM</h4>
                                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <form [formGroup]="modalForm" (ngSubmit)="addItem(tarea.id)">
                                        <div class="form-group">
                                            <label>Nombre del item</label>
                                            <div class="input-group">
                                                <input class="form-control" formControlName="itemName" id="itemName"
                                                       type="text" placeholder="Nombre del item." name="item"
                                                       required>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Descripición</label>
                                            <div class="input-group">
                                                <input class="form-control " formControlName="itemDescripcion"
                                                       id="itemDescipcion" type="text"
                                                       placeholder="Descripción breve del ítem." name="jornadaHoras"
                                                       required>

                                            </div>
                                        </div>
                                        <div class="form-check">
                                            <button type="submit" [disabled]="!modalForm.valid"
                                                    class="btn btn-outline-dark">Añadir
                                            </button>
                                            <label class="ml-1" *ngIf="!modalForm.valid">* Debe completar los campos
                                                especificados.</label>
                                        </div>
                                    </form>
                                </div>

                            </ng-template>

                            <!-- Modal edi -->
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>


                <!-- Modal tarea -->
                <ng-template #tareaADD let-modal>
                    <div class="modal-header">
                        <h4 class="modal-title " id="modal-basic-title-2">AÑADIR TAREA</h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form [formGroup]="modalForm" (ngSubmit)="addtarea(proyecto.id)">
                            <div class="form-group">
                                <label>Nombre de la tarea</label>
                                <div class="input-group">
                                    <input class="form-control" formControlName="tareaName" id="tareaName" type="text"
                                           placeholder="Nombre de la tarea." name="tarea"
                                           required>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Descripición</label>
                                <div class="input-group">
          <textarea class="form-control " formControlName="tareaDescripcion" id="tareaDescipcion" type="text"
                    placeholder="Descripción breve de la tarea." name="jornadaHoras"
                    required></textarea>
                                </div>
                            </div>
                            <div>
                                <button type="submit" [disabled]="!modalForm.valid" class="btn btn-outline-dark">
                                    Añadir
                                </button>
                                <label class="ml-1" *ngIf="!modalForm.valid">* Debe completar los campos
                                    especificados.</label>
                            </div>
                        </form>
                    </div>

                </ng-template>

            </ng-template>
        </ngb-panel>

    </ngb-accordion>

</div>
<!-- Modal add proyecto -->
<ng-template #proyectoADD let-modal>
    <div class="modal-header">
        <h4 class="modal-title " id="modal-basic-title">AÑADIR PROYECTO</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="modalForm" (ngSubmit)="addProyecto()">
            <div class="form-group">
                <label>Nombre del proyecto</label>
                <div class="input-group">
                    <input class="form-control" formControlName="proyectoName" id="proyectoName" type="text"
                           placeholder="Nombre del proyecto." name="jornada"
                           required>
                </div>
            </div>
            <div class="form-group">
                <label>Descripición</label>
                <div class="input-group">
          <textarea class="form-control " formControlName="proyectoDescripcion" id="jornadaHoras" type="text"
                    placeholder="Descripción breve del proyecto." name="jornadaHoras"
                    required></textarea>
                </div>
            </div>
            <div>
                <button type="submit" [disabled]="!modalForm.valid" class="btn btn-outline-dark">Añadir</button>
                <label class="ml-1" *ngIf="!modalForm.valid">* Debe completar los campos especificados.</label>
            </div>
        </form>
    </div>

</ng-template>
