import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../pages/services/login.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

    constructor(private router: Router, private _login: LoginService) {

    }
}
