// Importamos los componentes necesarios
import { Pipe, PipeTransform } from '@angular/core';

// Le ponemos un nombre a la tuberia
@Pipe({ name: 'seguimiento' })

// Definimos la clase implementado la interface PipeTransform
export class SeguimientoPipe implements PipeTransform {
  transform(value: string, hoy: boolean, tipo: boolean): string {
    const fechahoy = new Date().toISOString().substring(0, 10);
    if (hoy) {
      if (tipo) {
        return value;
      } else {
        if (value === 'ENTRADA') {
          return value;
        } else {
          return value;
        }
      }

    } else {
      if (tipo) {
        return '';
      } else {
        return 'POR FICHAR';
      }
    }
  }

}
