import { RolePipe } from './pipes/role.pipe';

import { ImagesPipe } from './pipes/images.pipe';
import { ContactosPipe } from './pipes/contactos.pipe';
import { UserPipe } from './pipes/user.pipe';
import { ImagesInboxPipe } from './pipes/imagesInbox.pipe';
import { AcortarPipe } from './pipes/acortar.pipe';
import { FromMillisecondsPipe } from './pipes/fromMilliseconds.pipe';
import { MailPipe } from './pipes/mail.pipe';
import { RecortarRolePipe } from './pipes/recortar-role.pipe';
import { HorasMinutosPipe } from './pipes/horasMinutos.pipe';
import { FicharPipe } from './pipes/fichar.pipe';
import { SeguimientoPipe } from './pipes/seguimiento.pipe';
import { ReordenarArrayPipe } from './pipes/reordenarArray.pipe';
import { NombreMesPipe } from './pipes/nombreMes.pipe';
import {NgModule} from "@angular/core";
import {HorasCalculoPipe} from "./pipes/horas-calculo.pipe";
import {NombreEmpleadoPipe} from "./pipes/nombre-empleado.pipe";

@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [
    ImagesPipe,
    RolePipe,
    ContactosPipe,
    UserPipe,
    ImagesInboxPipe,
    AcortarPipe,
    FromMillisecondsPipe,
    MailPipe,
    RecortarRolePipe,
    HorasMinutosPipe,
    FicharPipe,
    ReordenarArrayPipe,
    SeguimientoPipe,
    NombreMesPipe,
    HorasCalculoPipe,
      NombreEmpleadoPipe
  ],
  exports: [
    ImagesPipe,
    RolePipe,
    ContactosPipe,
    UserPipe,
    ImagesInboxPipe,
    AcortarPipe,
    FromMillisecondsPipe,
    MailPipe,
    RecortarRolePipe,
    HorasMinutosPipe,
    FicharPipe,
    ReordenarArrayPipe,
    SeguimientoPipe,
    NombreMesPipe,
      HorasCalculoPipe,
      NombreEmpleadoPipe
  ]
})
export class PipesModule { }
