<!-- NAVBAR -->
<app-navbar></app-navbar>

<div #wrapper class="d-flex" id="wrapper">

    <!-- Sidebar -->
    <div class="bg-light border-right" id="sidebar-wrapper">

        <!-- SIDEBAR -->
        <app-sidebar></app-sidebar>

    </div>
    <!-- /#sidebar-wrapper -->
    <!-- Page Content -->
    <div id="content-wrapper">
        <div class="container-fluid">
            <!-- ROUTER OUTLET -->
            <router-outlet></router-outlet>
        </div>
    </div>
    <!-- /#page-content-wrapper -->

</div>
<!-- /#wrapper -->


<!-- FOOTER -->
<app-footer></app-footer>