// Importamos los componentes necesarios
import { Pipe, PipeTransform } from '@angular/core';

// Le ponemos un nombre a la tuberia
@Pipe({ name: 'minutos' })

// Definimos la clase implementado la interface PipeTransform
export class HorasMinutosPipe implements PipeTransform {
  transform(value: number): string {
    if (value) {
      const valores = String(value.toFixed(2)).split('.');
      const horas = valores[0];
      const minutos = Number(valores[1]);
      let minuto: string;
      if (minutos) {
        minuto = String(((60 * minutos) / 100).toFixed(0));
      } else {
        minuto = '00';
      }
      return `${horas} h ${minuto}'`;
    }
  }
}
