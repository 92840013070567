import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'horasCalculo'
})
export class HorasCalculoPipe implements PipeTransform {

  transform(dif: number): string {
    // console.log('entro', dif);

    if (dif === 0){
      // console.log('if');
      return '0';
    }else {
      var minutos = ((dif/(1000*60))%60);
      var horas = ((dif/(1000*60*60))%24);
      // console.log('else');
      return `${Math.floor(horas)} h ${Math.floor(minutos)}`;
    }
  }

}
